
// Import css/CardContainer.css into CardContainer.js
import './css/CardContainer.css';

// Import ../images/WillFort3.png 
import WillFort3 from '../images/willfort3.png';

const CardContainer = () => {
    return(
        <div className="card-container">
            <div class="p-20 w-full md:w-1/2">
                <div class="bg-white rounded-lg shadow-lg">

                    <img src={WillFort3} alt="" class="rounded-t-lg" />

                    <div class="p-6">
                        <h2 class="font-bold mb-2 text-2xl">Will Fort</h2>
                        <p class="text-purple-700 mb-2">Development Operations Engineer</p>
                    </div>
                </div>
            </div>

        </div>  
    )

}

export default CardContainer;