
import './index.css';

//Component imports
import CardContainer from './components/CardContainer';

function App() {
  return (
    <div className="App">
      <CardContainer />
    </div>
  );
}

export default App;
